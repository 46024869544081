/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.messageBar {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  box-sizing: border-box;
  font-size: 15px;
  border-radius: 5px;
  height: 100%;
  z-index: 10;
  position: relative;
  background: #fcf9f5;
}
@media (min-width: 768px) {
  .messageBar {
    font-size: 23px;
  }
}
.messageBar > .sprite {
  float: left;
  width: 48px;
  margin: 0 20px 20px -20px;
}
@media (max-width: 767px) {
  .messageBar > .sprite {
    display: none;
  }
}
.messageBar.info {
  color: #fff;
  background: #35c5ff;
  font-size: 18px;
}
.messageBar.success {
  color: #fff;
  background: #52b958;
}
.messageBar.success a {
  color: #ffce3c;
}
.messageBar.success a:hover {
  color: #ffc109;
}
.messageBar.warning {
  color: #fff;
  background-color: #f24747;
}
.greyBox > .messageBar {
  text-align: left;
  padding: 30px 40px;
  margin: -40px 0 0 -40px;
  box-sizing: content-box;
  font-size: 17px;
  min-height: 60px;
}
.greyBox > .messageBar ul {
  list-style-image: url('/graphics/generic/hyperlink-arrow.svg');
  padding-left: 30px;
  margin: 0;
}
.greyBox > .messageBar ul > li {
  font-size: 20px;
  margin-bottom: 7px;
}
.greyBox > .messageBar > .button {
  margin-left: 40px;
  float: right;
}
@media (max-width: 767px) {
  .greyBox > .messageBar > .button {
    float: none;
    display: block;
    margin-bottom: 30px;
  }
}
