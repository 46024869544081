/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.forgot_password,
.reset_password_page,
.login_page {
  background: #f9f6ef;
  position: relative;
  padding-bottom: 20rem;
  padding-top: 5rem;
}
.forgot_password .buttonWrapper,
.reset_password_page .buttonWrapper,
.login_page .buttonWrapper {
  width: 100%;
  margin-top: 3rem;
  text-align: center;
}
.forgot_password .buttonWrapper .button,
.reset_password_page .buttonWrapper .button,
.login_page .buttonWrapper .button {
  width: 30%;
  padding: 2rem;
  background: #ffdc4a;
  color: #534468;
}
.forgot_password .buttonWrapper .button:hover,
.reset_password_page .buttonWrapper .button:hover,
.login_page .buttonWrapper .button:hover {
  background: #ffd217;
  color: #534468;
}
.forgot_password .title,
.reset_password_page .title,
.login_page .title {
  font-size: 3em;
  font-weight: 600;
  color: #745a9e;
  font-family: 'Zona Pro', 'Verdana';
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (max-width: 991px) {
  .forgot_password .title,
  .reset_password_page .title,
  .login_page .title {
    font-size: 2.2em;
    margin-left: 1rem;
  }
}
.forgot_password .wrapper,
.reset_password_page .wrapper,
.login_page .wrapper {
  font-size: 1.6rem;
  border-radius: 2rem;
  max-width: 110rem;
  margin: 0 auto;
}
.forgot_password .wrapper label,
.reset_password_page .wrapper label,
.login_page .wrapper label {
  font-weight: 600;
  font-size: 1.3rem;
  color: #745a9e;
  margin-top: 5rem;
  text-align: left;
  padding-left: 3rem;
}
.forgot_password .wrapper .validatedInput,
.reset_password_page .wrapper .validatedInput,
.login_page .wrapper .validatedInput {
  margin-top: 3rem;
  border: 1px solid #f9f6ef;
  border-radius: 0.7rem;
  background: #f9f6ef;
}
.forgot_password .wrapper input,
.reset_password_page .wrapper input,
.login_page .wrapper input {
  border: 2px solid #f9f6ef;
  padding: 1rem;
  font-size: 1.8rem;
  width: 100%;
  margin: 0;
  height: 5rem;
  border: 0;
  background: transparent;
}
.forgot_password .wrapper .row,
.reset_password_page .wrapper .row,
.login_page .wrapper .row {
  background: white;
  max-width: 70rem;
  min-width: 30rem;
  margin: 2rem auto 0;
  box-shadow: 0 0.7rem 1.4rem 0 rgba(60, 66, 87, 0.08), 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;
}
.forgot_password .wrapper .forgotPassword,
.reset_password_page .wrapper .forgotPassword,
.login_page .wrapper .forgotPassword {
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  margin-top: 4rem;
}
.forgot_password .wrapper .forgotPassword a,
.reset_password_page .wrapper .forgotPassword a,
.login_page .wrapper .forgotPassword a {
  color: #534468;
}
.forgot_password .wrapper > .notRegistered,
.reset_password_page .wrapper > .notRegistered,
.login_page .wrapper > .notRegistered {
  display: none;
}
.forgot_password .wrapper > .notRegistered a,
.reset_password_page .wrapper > .notRegistered a,
.login_page .wrapper > .notRegistered a {
  color: #000;
}
.forgot_password .bottomLeft,
.reset_password_page .bottomLeft,
.login_page .bottomLeft {
  position: absolute;
  bottom: -8rem;
  left: 2rem;
}
@media (max-width: 767px) {
  .forgot_password .bottomLeft,
  .reset_password_page .bottomLeft,
  .login_page .bottomLeft {
    display: none;
  }
}
.forgot_password .topRight,
.reset_password_page .topRight,
.login_page .topRight {
  position: absolute;
  top: 0;
  right: 3rem;
  z-index: 5;
}
@media (max-width: 767px) {
  .forgot_password .topRight,
  .reset_password_page .topRight,
  .login_page .topRight {
    display: none;
  }
}
.forgot_password .cloud1,
.reset_password_page .cloud1,
.login_page .cloud1 {
  position: absolute;
  top: 30rem;
  left: -7rem;
}
@media (max-width: 767px) {
  .forgot_password .cloud1,
  .reset_password_page .cloud1,
  .login_page .cloud1 {
    display: none;
  }
}
.forgot_password .cloud2,
.reset_password_page .cloud2,
.login_page .cloud2 {
  position: absolute;
  top: 13rem;
  right: 5rem;
}
@media (max-width: 767px) {
  .forgot_password .cloud2,
  .reset_password_page .cloud2,
  .login_page .cloud2 {
    display: none;
  }
}
.forgot_password .cloud3,
.reset_password_page .cloud3,
.login_page .cloud3 {
  position: absolute;
  top: 42rem;
  right: -9rem;
}
@media (max-width: 767px) {
  .forgot_password .cloud3,
  .reset_password_page .cloud3,
  .login_page .cloud3 {
    display: none;
  }
}
.forgot_password .forgotPasswordForm.wrapper,
.reset_password_page .forgotPasswordForm.wrapper,
.login_page .forgotPasswordForm.wrapper {
  padding-bottom: 8rem;
}
.forgot_password .cardLogin,
.reset_password_page .cardLogin,
.login_page .cardLogin {
  position: absolute;
  z-index: 1;
  left: -3.5rem;
  bottom: -5rem;
  pointer-events: none;
}
@media (max-width: 767px) {
  .forgot_password .cardLogin,
  .reset_password_page .cardLogin,
  .login_page .cardLogin {
    display: none;
  }
}
.forgot_password .message,
.reset_password_page .message,
.login_page .message {
  margin-left: -1.4rem;
  margin-bottom: 30rem;
}
.forgot_password .caption,
.reset_password_page .caption,
.login_page .caption {
  font-size: 1.6rem;
}
